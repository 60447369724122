import React from 'react';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
//import Lightbox from 'react-lightbox-component'
import Lightbox from '../../react-lightbox-component';
import "./SaltybridgeGallery.scss"

import img_1 from '../../../images/ortho-gallery/MD_06506.jpg';
import img_2 from '../../../images/ortho-gallery/MD_06511.jpg';
import img_3 from '../../../images/ortho-gallery/MD_06517.jpg';
import img_4 from '../../../images/ortho-gallery/MD_06524.jpg';
import img_5 from '../../../images/ortho-gallery/MD_06526.jpg';
import img_6 from '../../../images/ortho-gallery/MD_06534.jpg';

export default function SaltybridgeGallery() {
    const { t } = useTranslation()
    const images = 
        [
            {
                src: img_1,
                title: t('sbcom_ortho_gallery_title1'),
                description: t('sbcom_ortho_gallery_description1')
            },
            {
                src: img_2,
                title: t('sbcom_ortho_gallery_title2'),
                description: t('sbcom_ortho_gallery_description2')
            },
            {
                src: img_3,
                title: t('sbcom_ortho_gallery_title3'),
               description: t('sbcom_ortho_gallery_description3')
            },
            {
                src: img_4,
                title: t('sbcom_ortho_gallery_title4'),
                description: t('sbcom_ortho_gallery_description4')
            },
            {
                src: img_5,
                title: t('sbcom_ortho_gallery_title5'),
                description: t('sbcom_ortho_gallery_description5')
            },
            {
                src: img_6,
                title: t('sbcom_ortho_gallery_title6'),
                description: t('sbcom_ortho_gallery_description6')
            }
        ]

    return (
      <div id="sbcom-gallery">
          <div className="container">
            <Lightbox 
            images={images}
            showImageModifiers={false}
            thumbnailWidth='360px'
            thumbnailHeight='auto'
            />
        </div>
      </div>)

  }

  /*export const pageQuery = graphql`
    query {
        gallery: allFile(filter: {relativeDirecotory: {eq: "orth-gallery" }})
        {
            edges {
                node {
                    id
                    base
                    publicURL
                    shildImageSharp {
                        gatsbyImageData(
                            height: 600
                            width: 900
                            transformOptions: {fit: COVER}
                            placeholder: BLURRED
                            webpOptions: {quality: 50}
                        )
                    }
                }
            }
        }
    }
  `*/